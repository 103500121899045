/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.29.12.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenRefreshRequest,
  TokenResponse,
  TokenRetreiveRequest,
} from '../models';
import {
    TokenRefreshRequestFromJSON,
    TokenRefreshRequestToJSON,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    TokenRetreiveRequestFromJSON,
    TokenRetreiveRequestToJSON,
} from '../models';

export interface AuthTokenCreateRequest {
    tokenRetreiveRequest: TokenRetreiveRequest;
}

export interface AuthTokenRefreshCreateRequest {
    tokenRefreshRequest: TokenRefreshRequest;
}

/**
 * 
 */
export class TokenApi extends runtime.BaseAPI {

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async authTokenCreateRaw(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.tokenRetreiveRequest === null || requestParameters.tokenRetreiveRequest === undefined) {
            throw new runtime.RequiredError('tokenRetreiveRequest','Required parameter requestParameters.tokenRetreiveRequest was null or undefined when calling authTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRetreiveRequestToJSON(requestParameters.tokenRetreiveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async authTokenCreate(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.authTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async authTokenRefreshCreateRaw(requestParameters: AuthTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.tokenRefreshRequest === null || requestParameters.tokenRefreshRequest === undefined) {
            throw new runtime.RequiredError('tokenRefreshRequest','Required parameter requestParameters.tokenRefreshRequest was null or undefined when calling authTokenRefreshCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshRequestToJSON(requestParameters.tokenRefreshRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async authTokenRefreshCreate(requestParameters: AuthTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.authTokenRefreshCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
