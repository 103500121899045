/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.29.12.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OriginDestinationListResponse,
} from '../models';
import {
    OriginDestinationListResponseFromJSON,
    OriginDestinationListResponseToJSON,
} from '../models';

/**
 * 
 */
export class OriginDestinationApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving a list of origin-destination combinations.
     */
    async apiOriginDestinationListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OriginDestinationListResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/origin-destination/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OriginDestinationListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of origin-destination combinations.
     */
    async apiOriginDestinationList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OriginDestinationListResponse>> {
        const response = await this.apiOriginDestinationListRaw(initOverrides);
        return await response.value();
    }

}
