/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.29.12.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DestinationGroupCreateRequest,
  DestinationGroupCreateResponse,
  DestinationGroupListResponse,
} from '../models';
import {
    DestinationGroupCreateRequestFromJSON,
    DestinationGroupCreateRequestToJSON,
    DestinationGroupCreateResponseFromJSON,
    DestinationGroupCreateResponseToJSON,
    DestinationGroupListResponseFromJSON,
    DestinationGroupListResponseToJSON,
} from '../models';

export interface ApiDestinationGroupCreateRequest {
    destinationGroupCreateRequest: DestinationGroupCreateRequest;
}

export interface ApiDestinationGroupDestroyRequest {
    id: number;
}

export interface ApiDestinationGroupUpdateRequest {
    id: number;
    destinationGroupCreateRequest: DestinationGroupCreateRequest;
}

/**
 * 
 */
export class DestinationGroupApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a new custom destination layer.
     */
    async apiDestinationGroupCreateRaw(requestParameters: ApiDestinationGroupCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DestinationGroupCreateResponse>> {
        if (requestParameters.destinationGroupCreateRequest === null || requestParameters.destinationGroupCreateRequest === undefined) {
            throw new runtime.RequiredError('destinationGroupCreateRequest','Required parameter requestParameters.destinationGroupCreateRequest was null or undefined when calling apiDestinationGroupCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/destination-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DestinationGroupCreateRequestToJSON(requestParameters.destinationGroupCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DestinationGroupCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a new custom destination layer.
     */
    async apiDestinationGroupCreate(requestParameters: ApiDestinationGroupCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DestinationGroupCreateResponse> {
        const response = await this.apiDestinationGroupCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a custom destination layer.
     */
    async apiDestinationGroupDestroyRaw(requestParameters: ApiDestinationGroupDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDestinationGroupDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/destination-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a custom destination layer.
     */
    async apiDestinationGroupDestroy(requestParameters: ApiDestinationGroupDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiDestinationGroupDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of custom destination layers.
     */
    async apiDestinationGroupListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DestinationGroupListResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/destination-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DestinationGroupListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of custom destination layers.
     */
    async apiDestinationGroupList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DestinationGroupListResponse>> {
        const response = await this.apiDestinationGroupListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a custom destination layer.
     */
    async apiDestinationGroupUpdateRaw(requestParameters: ApiDestinationGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DestinationGroupCreateResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDestinationGroupUpdate.');
        }

        if (requestParameters.destinationGroupCreateRequest === null || requestParameters.destinationGroupCreateRequest === undefined) {
            throw new runtime.RequiredError('destinationGroupCreateRequest','Required parameter requestParameters.destinationGroupCreateRequest was null or undefined when calling apiDestinationGroupUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/destination-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DestinationGroupCreateRequestToJSON(requestParameters.destinationGroupCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DestinationGroupCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a custom destination layer.
     */
    async apiDestinationGroupUpdate(requestParameters: ApiDestinationGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DestinationGroupCreateResponse> {
        const response = await this.apiDestinationGroupUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
