import React from "react";
import "./MenuTitle.css";

interface MenuTitleProps {
  children?: React.ReactNode;
  title?: string;
  onClick?: () => void;
  button?: boolean;
  button_content?: any;
  icon?: any;
  transparentButtonBackground?: boolean;
  no_margin?: boolean;
}

export default class MenuTitle extends React.Component<MenuTitleProps> {
  render() {
    const style = {
      marginTop: this.props.no_margin ? "0px" : "10px",
    };
    return (
      <div className="MenuTitle" style={style}>
        {" "}
        <p className="Title">
          {this.props.icon} {this.props.title}
        </p>
        {this.props.button ? (
          <button
            className={
              this.props.transparentButtonBackground
                ? "MenuTitleBtn transparent"
                : "MenuTitleBtn"
            }
            onClick={() => {
              if (this.props.onClick !== undefined) {
                this.props.onClick();
              }
            }}
          >
            {this.props.button_content}
          </button>
        ) : null}
      </div>
    );
  }
}
