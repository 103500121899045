/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.29.12.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * 
 * @export
 * @interface DestinationGroupCreateResponse
 */
export interface DestinationGroupCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof DestinationGroupCreateResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DestinationGroupCreateResponse
     */
    name: string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof DestinationGroupCreateResponse
     */
    type: TypeEnum;
}

/**
 * Check if a given object implements the DestinationGroupCreateResponse interface.
 */
export function instanceOfDestinationGroupCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DestinationGroupCreateResponseFromJSON(json: any): DestinationGroupCreateResponse {
    return DestinationGroupCreateResponseFromJSONTyped(json, false);
}

export function DestinationGroupCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationGroupCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': TypeEnumFromJSON(json['type']),
    };
}

export function DestinationGroupCreateResponseToJSON(value?: DestinationGroupCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': TypeEnumToJSON(value.type),
    };
}

