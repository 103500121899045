import { LatLng, LatLngBounds, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import {
  CircleMarker,
  FeatureGroup,
  MapContainer,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import "./MapOriginDestinationNetworkDiff.css";

interface ParsedOriginDestinationNetworkDiff {
  centerPoint: LatLng;
  roads: {
    geometry: LatLng[];
    group: number;
    value: number;
  }[];
  points: {
    id: number;
    geometry: LatLng;
  }[];
}

interface MapOriginDestinationNetworkDiffProps {
  geoJsonString: string;
}

export default class MapOriginDestinationNetworkDiff extends React.Component<MapOriginDestinationNetworkDiffProps> {
  center: LatLng = new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 7;

  NetworkColors: {
    group: number;
    weight: number;
    color: string;
  }[] = [
    {
      group: 1,
      weight: 9,
      color: "#D7191C",
    },
    {
      group: 2,
      weight: 7,
      color: "#E85B3B",
    },
    {
      group: 3,
      weight: 5,
      color: "#F99D59",
    },
    {
      group: 4,
      weight: 3,
      color: "#FEC980",
    },
    {
      group: 5,
      weight: 1,
      color: "#FFEDAA",
    },
    {
      group: 6,
      weight: 1,
      color: "#ECF7B9",
    },
    {
      group: 7,
      weight: 3,
      color: "#C7E8AD",
    },
    {
      group: 8,
      weight: 5,
      color: "#9DD3A6",
    },
    {
      group: 9,
      weight: 7,
      color: "#64ABB0",
    },
    {
      group: 10,
      weight: 9,
      color: "#2B83BA",
    },
  ];

  zoomToBounds(map: Map, roadsObject: ParsedOriginDestinationNetworkDiff) {
    if (roadsObject.roads.length > 0) {
      let north: number | null = null;
      let east: number | null = null;
      let south: number | null = null;
      let west: number | null = null;

      roadsObject.roads.forEach((road) => {
        road.geometry.forEach((point) => {
          if (
            north === null &&
            east === null &&
            south === null &&
            west === null
          ) {
            north = point.lat;
            east = point.lng;
            south = point.lat;
            west = point.lng;
          } else if (north && east && south && west) {
            if (point.lat > north) {
              north = point.lat;
            }
            if (point.lng > east) {
              east = point.lng;
            }
            if (point.lat < south) {
              south = point.lat;
            }
            if (point.lng < west) {
              west = point.lng;
            }
          }
        });
      });

      if (north && east && south && west) {
        map.fitBounds(
          new LatLngBounds(new LatLng(south, west), new LatLng(north, east))
        );
      } else {
        map.setView(roadsObject.centerPoint, 12);
      }
    } else {
      map.setView(roadsObject.centerPoint, 12);
    }
  }

  parseJsonString(geojson: string): ParsedOriginDestinationNetworkDiff {
    const returnData: ParsedOriginDestinationNetworkDiff = {
      centerPoint: this.center,
      roads: [],
      points: [],
    };

    try {
      // parse json
      const originDestinationNetworkObject = JSON.parse(geojson);

      // validate data then return
      if (
        "roads" in originDestinationNetworkObject &&
        "center" in originDestinationNetworkObject
      ) {
        // Get grid items
        originDestinationNetworkObject.roads.forEach((road: any) => {
          if ("geometry" in road && "group" in road && "value" in road) {
            returnData.roads.push({
              geometry: road.geometry.map(
                (c: number[]) => new LatLng(c[1], c[0])
              ),
              group: road.group,
              value: road.value,
            });
          }
        });
      }

      if (
        "points" in originDestinationNetworkObject &&
        "center" in originDestinationNetworkObject
      ) {
        // Get grid items
        originDestinationNetworkObject.points.forEach((point: any) => {
          returnData.points.push({
            id: point.id,
            geometry: new LatLng(point.y, point.x),
          });
        });

        // Get the center point
        if (
          "latitude" in originDestinationNetworkObject.center &&
          "longitude" in originDestinationNetworkObject.center
        ) {
          returnData.centerPoint = new LatLng(
            originDestinationNetworkObject.center.latitude,
            originDestinationNetworkObject.center.longitude
          );
        }
      }

      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        centerPoint: this.center,
        roads: [],
        points: [],
      };
    }
  }

  parseOverviewJsonString(geojson: string): LatLng[] | null {
    let returnData: LatLng[] | null = null; // The array of LatLng points to return

    try {
      // Parse the GeoJSON string
      const overviewObject = JSON.parse(geojson.replaceAll("'", '"'));

      // Validate the data
      if ("coordinates" in overviewObject && "type" in overviewObject) {
        // Check if the GeoJSON object represents a Polygon with valid coordinates
        if (
          overviewObject.type === "Polygon" &&
          overviewObject.coordinates.length > 0
        ) {
          // Map the coordinates to an array of LatLng points
          returnData = overviewObject.coordinates[0].map(
            (coordinate: number[]) => {
              return new LatLng(coordinate[1], coordinate[0]);
            }
          );
        }
      }

      // Return the parsed data
      return returnData;
    } catch (error) {
      // Log and handle any parsing errors
      console.error("Error parsing JSON:", error);
      return null;
    }
  }

  AddCustomPanes = () => {
    const map = useMap();

    useEffect(() => {
      this.NetworkColors.forEach((group) => {
        map.createPane(`pane_${group.group}`);
        map.getPane(`pane_${group.group}`)!.style.zIndex = `${
          650 + group.group
        }`;
      });
    }, [map]);

    return null;
  };

  OriginDestinationNetwork = (): JSX.Element => {
    const map = useMap();

    // From string to roads object
    const roadsObject = this.parseJsonString(this.props.geoJsonString);

    // Zoom to layer
    this.zoomToBounds(map, roadsObject);

    // Draw on the map
    return (
      <FeatureGroup>
        {roadsObject.roads.map((line, index) => {
          return (
            <Polyline
              key={index}
              pathOptions={{
                weight: (
                  this.NetworkColors.find(
                    ({ group }) => group === line.group
                  ) || { weight: 2 }
                ).weight,
                color: (
                  this.NetworkColors.find(
                    ({ group }) => group === line.group
                  ) || { color: "#2b83ba" }
                ).color,
              }}
              positions={line.geometry}
              pane={`pane_${line.group}`}
            />
          );
        })}
        {roadsObject.points.map((point, index) => {
          return (
            <CircleMarker
              key={index}
              center={point.geometry}
              radius={3}
              weight={0}
              fillColor={"#2b83ba"}
              opacity={1}
              fillOpacity={1}
            />
          );
        })}
      </FeatureGroup>
    );
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        zoomControl={false}
        attributionControl={true}
        id={"MapOriginDestinationNetwork"}
      >
        <this.AddCustomPanes />
        <TileLayer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
        <this.OriginDestinationNetwork />
      </MapContainer>
    );
  }
}
