import React from "react";
import { FaPlus } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import { GrUserAdmin } from "react-icons/gr";
import { MdAddLocationAlt, MdLogout, MdOutlineFeed } from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, ScreenState } from "src/Types";
import { ScenarioTypeEnum } from "src/client/lib/models";

import MenuTitle from "./MenuTitle";
import "./ProjectBar.css";

interface ProjectBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ProjectBar extends React.Component<ProjectBarProps> {
  render() {
    return (
      <div id="ProjectBar">
        <div className="SmallMenu-container">
          <div className="SmallMenu-logo"></div>
          <div className="SmallMenu-buttons">
            <button
              className="SmallMenu-button"
              onClick={() => {
                this.props.stateHandler.changeScreen(
                  ScreenState.CustomAnalysisLayersScreen,
                  this.props.updateState
                );
              }}
              title="Bestemmingen"
            >
              <MdAddLocationAlt />
            </button>
            {this.props.state.current_user.is_staff ? (
              <button
                className="SmallMenu-button"
                onClick={() => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.AdminScreen,
                    this.props.updateState
                  );
                }}
                title="Admin"
              >
                <GrUserAdmin />
              </button>
            ) : (
              <button
                className="SmallMenu-button"
                onClick={() => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.DocsScreen,
                    this.props.updateState
                  );
                }}
                title="Handleiding"
              >
                <MdOutlineFeed />
              </button>
            )}

            <button
              className="SmallMenu-button"
              onClick={() => {
                this.props.stateHandler.logout(this.props.updateState);
              }}
              title="Uitloggen"
            >
              <MdLogout />
            </button>
          </div>
        </div>
        <MenuTitle
          title="Projecten"
          onClick={() => {
            this.props.stateHandler.changeScreen(
              ScreenState.NewProject,
              this.props.updateState
            );
          }}
          button={true}
          button_content={<FaPlus size={25} />}
          transparentButtonBackground={false}
          no_margin={true}
        />
        <div id="ProjectBar-ListHolder">
          <ul id="ProjectBar-List">
            {this.props.state.projects.map((project) => (
              <li key={project.id} className={"ProjectBar-ProjectItem"}>
                <span
                  className={
                    project.id === this.props.state.selectedProject
                      ? "ProjectBar-ProjectName selected"
                      : "ProjectBar-ProjectName"
                  }
                  onClick={() => {
                    this.props.stateHandler.changeSelectedProject(
                      project.id,
                      this.props.updateState
                    );
                  }}
                >
                  <GoProjectRoadmap /> {project.name}
                </span>

                <ul className="ProjectBar-Sublist">
                  {project.scenarioList.map((scenario) => (
                    <div key={scenario.id} className="ProjectListContainer">
                      <li
                        className={
                          scenario.id === this.props.state.selectedScenario
                            ? "selected"
                            : ""
                        }
                        onClick={() => {
                          this.props.stateHandler.changeSelectedScenario(
                            project.id,
                            scenario.id,
                            this.props.updateState
                          );
                        }}
                      >
                        <RiRoadMapLine />
                        &nbsp;&nbsp;{scenario.name}
                      </li>
                    </div>
                  ))}
                  {(() => {
                    if (
                      project.scenarioList.length > 0 &&
                      project.scenarioType !==
                        ScenarioTypeEnum.TravelTimeIsochrones
                    ) {
                      return (
                        <li
                          onClick={() => {
                            this.props.stateHandler.projectBarCreateNewScenario(
                              project.id,
                              this.props.updateState
                            );
                          }}
                        >
                          <FaPlus /> &nbsp;Nieuw scenario
                        </li>
                      );
                    }
                  })()}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
