import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class CreateCustomAnalysisLayersStateHandler {
  // New Analysis Layer screen
  initCreateCustomAnalysisLayersScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.CreateCustomAnalysisLayersPopUpVisible = true;
    this.state.AnalysisLayer.name = "";
    this.state.AnalysisLayer.destinations = [];
    updateState(this.state);
  }

  saveAnalysisLayer(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // Check if the layer has a name and at least one point
    if (
      this.state.AnalysisLayer.name === "" ||
      this.state.AnalysisLayer.destinations.length === 0
    ) {
      return;
    }

    // Map the points to GeoJSON features
    const points = this.state.AnalysisLayer.destinations.map((destination) => ({
      name: destination.name,
      geom: {
        type: "Point",
        coordinates: [destination.geom.lng, destination.geom.lat],
      },
    }));

    /**
     * Saves the analysis layer to the server using the CustomAnalysisLayersApi.
     * If successful, it changes the screen to the CustomAnalysisLayersScreen.
     * If there is an error, it logs the error to the console.
     */
    this.destinationGroupApi
      .apiDestinationGroupCreate({
        // Create the custom analysis layer object with the name and points
        destinationGroupCreateRequest: {
          name: this.state.AnalysisLayer.name,
          destinations: points,
          type: this.state.AnalysisLayer.type,
        },
      })
      .then(() => {
        // Change the screen to the CustomAnalysisLayersScreen
        // this.changeScreen(ScreenState.CustomAnalysisLayersScreen, updateState);
        this.closeCustomAnalysisLayersScreen(updateState);
        if (this.state.screenState === ScreenState.CustomAnalysisLayersScreen) {
          this.loadCustomAnalysisLayers(updateState);
        } else {
          this.fetchDestinationGroupList(updateState);
        }

        updateState(this.state);
      })
      .catch((error) => {
        // Log the error to the console
        console.log("Error while saving analysis layer", error);
      });
  }

  closeCustomAnalysisLayersScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.CreateCustomAnalysisLayersPopUpVisible = false;
    this.state.EditCustomAnalysisLayersPopUpVisible = false;
    updateState(this.state);
  }
}
